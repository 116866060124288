import model from './model';
import {
  ColorPickerWidgetEvents,
  ColorWidgetComponentIds as WidgetComponentIds,
  ColorItemComponentIds as ItemComponentIds,
  ColorWidgetComponentIds,
} from './config/constants';
import {AnimationService} from './animation-service/AnimationService';
import {autorun, makeAutoObservable} from 'mobx';
import {ColorPickerItemComponents} from './config/types';
import {ChoiceItem} from '../optionsNewWidget/config/types';

export default model.createController((controllerParams) => {
  const {$w, $widget, $props, $bindAll} = controllerParams;

  const getItemComponents = ($item: typeof $w): ColorPickerItemComponents => ({
    selectedCircle: $item(ItemComponentIds.SelectedCircle),
    defaultCircle: $item(ItemComponentIds.DefaultCircle),
    colorFill: $item(ItemComponentIds.ColorFill),
    itemWrapper: $item(ItemComponentIds.ItemWrapper),
    radioButton: $item(ItemComponentIds.RadioCircle),
  });
  const $widgetState = makeAutoObservable({
    get selections(): ChoiceItem[] {
      return $props.selections;
    },
    get selectedChoice() {
      return $widgetState.selections.find((choice) => $props.selectionIds.includes(choice.selectionId));
    },
    get isEmpty() {
      return $widgetState.selections.length === 0;
    },
  });

  const initItemHoverAnimations = (animationService: AnimationService) => ($item, itemId) => {
    const itemComponents = getItemComponents($item);
    itemComponents.itemWrapper.onMouseIn(
      /* istanbul ignore next reason: there is no animations testkit yet */ () =>
        animationService.playItemHover(itemId, getItemComponents($item)),
    );
    itemComponents.itemWrapper.onMouseOut(
      /* istanbul ignore next reason: there is no animations testkit yet */ () =>
        $widgetState.selectedChoice?._id !== itemId && animationService.reverseItemHover(itemId, itemComponents),
    );
  };

  const getChoice = (selections: ChoiceItem[], _id: string) => {
    return selections?.find((c) => c._id === _id);
  };

  const handleColorItemReady = (animationService: AnimationService) => ($item, itemData: ChoiceItem) => {
    const itemComponents = getItemComponents($item);
    const itemId = itemData._id;
    animationService.storeItemAnimation(itemId, itemComponents);
    initItemHoverAnimations(animationService)($item, itemId);
  };
  return {
    pageReady: () => {
      const animationService = new AnimationService((controllerParams.controllerConfig.wixCodeApi as any).animations);
      $w(WidgetComponentIds.Repeater).onItemReady(handleColorItemReady(animationService));
      $bindAll({
        [WidgetComponentIds.Repeater]: {
          data: (): ChoiceItem[] => {
            return $props.selections;
          },
          item: ({_id: itemId}) => {
            const $itemState = makeAutoObservable({
              get isDisabled() {
                return !!$itemState.choice?.isDisabled;
              },
              get isSelected() {
                return $widgetState.selectedChoice?._id === itemId;
              },
              get description() {
                return $itemState.choice?.description;
              },
              get selectionId() {
                return $itemState.choice?.selectionId;
              },
              get choice() {
                return getChoice($widgetState.selections, itemId);
              },
            });
            autorun(() => {
              $itemState.isSelected
                ? animationService.playItemSelection(itemId)
                : animationService.reverseItemSelection(itemId);
            });
            return {
              [ItemComponentIds.ItemWrapper]: {
                onClick: () => $widget.fireEvent(ColorPickerWidgetEvents.Change, $itemState.selectionId),
              },
              [ItemComponentIds.SelectedCircle]: {
                hidden: () => $props.isError,
              },

              [ItemComponentIds.DefaultCircle]: {
                hidden: () => $props.isError,
              },

              [ItemComponentIds.ErrorCircle]: {
                hidden: () => !$props.isError,
              },

              [ItemComponentIds.SelectedCrossLine]: {
                hidden: () => !$itemState.isDisabled,
              },

              [ItemComponentIds.DefaultCrossLine]: {
                hidden: () => !$itemState.isDisabled,
              },

              [ItemComponentIds.ErrorCrossLine]: {
                hidden: () => !$itemState.isDisabled,
              },
              [ItemComponentIds.ColorFill]: {
                style: {
                  backgroundColor: () => $itemState.choice?.value as string,
                },
              },
              [ItemComponentIds.RadioCircle]: {
                value: () => $itemState.description,
                radioGroupName: () => $props.label,
                accessibility: {
                  ariaAttributes: {
                    describedBy: () => $w(ColorWidgetComponentIds.ErrorMessage).getTextSelector(),
                    label: () => $itemState.description,
                  },
                },
                // missing singleRadioButton types
              } as any,
            };
          },
        },
        [WidgetComponentIds.Label]: {
          deleted: () => !$props.shouldShowTitle,
          text: () =>
            $widgetState.selectedChoice
              ? `${$props.label}: ${$widgetState.selectedChoice.description as string}`
              : $props.label,
        },
        [WidgetComponentIds.TopContainer]: {
          hidden: () => $widgetState.isEmpty,
          accessibility: {
            ariaAttributes: {
              labelledBy: () => $w(ColorWidgetComponentIds.Label),
            },
          },
        },
        [WidgetComponentIds.ErrorMessage]: {
          deleted: () => !$props.isError,
          text: () => ($props.isError ? $props.errorMessage : ''),
        },
      });
    },
    exports: {
      focus: () => {
        $w(ColorWidgetComponentIds.Repeater).forEachItem(($item, itemData, index) => {
          if (index === 0) {
            $item(ItemComponentIds.RadioCircle).focus();
          }
        });
      },
    },
  };
});
